.List {

    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    > li {
        list-style-type: none;
    }

}

.Controls {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 var(--spacing-large);
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5em;
        height: 2.5em;
        padding: 0.5em;
        border: 2px solid var(--colour-text-accent-primary);
        border-radius: 100%;
        background: transparent;
        &:disabled {
            visibility: hidden;
        }
        svg {
            fill: var(--colour-text-accent-primary);
            width: 2em;
            height: 2em;
        }
    }
    button:first-child {
        transform: rotate(180deg);
        margin-left: -2.5em;
    }
    button:last-child {
        margin-right: -2.5em;
    }

    [data-site='magazines'] & {
        display: none;
    }
}